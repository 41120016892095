import React from 'react'

const About = () => {
  return (
    <section
      id='about'
      className='partners-section py-8 px-2 text-center'>
      <div className='ft-inner-container'>
        <h1 className='text-2xl font-bold mt-3 mb-8' style={{ color: '#03AEB5' }}>
          Who we are
        </h1>
      </div>
      <div className='section-text max-w-2xl ml-auto mr-auto mb-4'>
        <p>We offer cutting-edge tech solutions that empower businesses to achieve their goals. Our suite of products is designed to streamline business operations, increase efficiency, and enhance customer experience. With a team of experts and partnerships with global companies, we deliver the latest technology and expertise to businesses of all sizes and industries.
</p>
      </div>
    </section>
  )
}

export default About