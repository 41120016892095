import { useState } from 'react';
import { Form, Button } from 'rsuite';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: handle form submission
  };

  return (
    <Form className='text-left max-w-xl ml-auto mr-auto px-4' fluid onSubmit={handleSubmit}>
      <Form.Group className='py-3'>
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control className='border-l-0 border-t-0 border-r-0 rounded-none bg-transparent' name="name" value={name} onChange={(e) => setName(e)} />
      </Form.Group>
      <Form.Group className='py-3'>
        <Form.ControlLabel>Email</Form.ControlLabel>
        <Form.Control className='border-l-0 border-t-0 border-r-0 rounded-none bg-transparent' name="email" type="email" value={email} onChange={(e) => setEmail(e)} />
      </Form.Group>
      <Form.Group className='py-3'>
        <Form.ControlLabel>Phone</Form.ControlLabel>
        <Form.Control className='border-l-0 border-t-0 border-r-0 rounded-none bg-transparent' name="phone" type="tel" value={phone} onChange={(e) => setPhone(e)} />
      </Form.Group>
      <Form.Group className='py-3'>
        <Form.ControlLabel>Company</Form.ControlLabel>
        <Form.Control className='border-l-0 border-t-0 border-r-0 rounded-none bg-transparent' name="company" value={company} onChange={(e) => setCompany(e)} />
      </Form.Group>
      <Form.Group className='py-3'>
        <Form.ControlLabel>Position</Form.ControlLabel>
        <Form.Control className='border-l-0 border-t-0 border-r-0 rounded-none bg-transparent' name="position" value={position} onChange={(e) => setPosition(e)} />
      </Form.Group>
      <Form.Group className='py-3'>
        <Form.ControlLabel>Message</Form.ControlLabel>
        <Form.Control className='border-l-0 border-t-0 border-r-0 rounded-none bg-transparent' name="message" rows={5} componentClass="textarea" value={message} onChange={(e) => setMessage(e)} />
      </Form.Group>
      <Button className='bg-oceanblue' appearance="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default ContactForm;