import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import styles from './index.module.css'
import Logo from './stayonline_logo.png'
// eslint-disable-next-line no-unused-vars

const menu = [
  {
    label: 'Home',
    path: '/',
    button: false,
    margin: 4,
    hash: true,
    active: true
  },
  {
    label: 'About Us',
    path: '/#about',
    button: false,
    margin: 4,
    hash: true,
    active: true
  },
  {
    label: 'Products',
    path: '/#products',
    button: false,
    margin: 4,
    hash: true,
    active: true
  },
  {
    label: 'Marketplace',
    path: '/marketplace',
    button: false,
    margin: 4,
    hash: false,
    active: true
  },
  {
    label: 'Partners',
    path: '/#partners',
    button: false,
    margin: 4,
    hash: true,
    active: true
  },
  {
    label: 'Contact Us',
    path: '/#contact',
    button: false,
    margin: 4,
    hash: true,
    active: true
  },
]


const Navigation = () => {
  return (
    <nav className='py-1 px-2 '>
      <div className={`flex nav-container p-1 items-center justify-between ${styles.navigation}`}>
        <div className='site-brand'>
          <Link
            to='/'>
            <img src={Logo} alt='Stayonline Logo' className='site-logo' />
          </Link>
        </div>
        <div className='site-menu flex-grow max-w-lg'>
          <ul className={`navigation flex justify-between items-center ${styles.menu}`}>
            {menu.map((item, idx) =>
              <li key={idx} className={`mr-${item.margin}`}>
                {item.hash
                  ? <HashLink
                    to={item.path}
                    className={`nav-link text-inherit cursor-pointer ${item.button ? 'kwezi-btn-link p-2' : ''} ${item.active ? '' : 'disabled'}`}>{item.label}</HashLink>
                  :
                  <Link
                    to={item.path}
                    className={`nav-link text-inherit cursor-pointer ${item.button ? 'kwezi-btn-link p-2' : ''} ${item.active ? '' : 'disabled'}`}>
                    {item.label}
                  </Link>
                }
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={`bottom ${styles.navigation}`}>
        <hr className='opacity-40' />
      </div>
    </nav>
  )
}

const Header = () => {
  return (
    <Navigation />
  )
}

export default Header