import React from 'react'
import About from '../components/Pages/Landing/About'
import Contact from '../components/Pages/Landing/Contact'
import LandingHero from '../components/Pages/Landing/Hero'
import Partners from '../components/Pages/Landing/Partners'
import Products from '../components/Pages/Landing/Products'
import DefaultLayout from '../layouts/default'

const Landing = () => {
  return (
    <DefaultLayout>
      <LandingHero />
      <About />
      <Products />
      <Partners />
      <Contact />
    </DefaultLayout>
  )
}

export default Landing