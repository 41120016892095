import React from 'react'
import { MdOutlineSportsEsports } from 'react-icons/md'
import { MdOutlinePayments } from 'react-icons/md'
import { motion } from 'framer-motion'
import { GiReceiveMoney } from 'react-icons/gi'
import { SiContactlesspayment } from 'react-icons/si'
import { BiCheckShield } from 'react-icons/bi'
import { FaHandsHelping } from 'react-icons/fa'

const Products = () => {
  const featureList = [
    {
      label: 'eSports Corporate Solution',
      description: 'Boost & monetize your brand with Africa\'s eSports Solution: engage 2B+ gamers, attract sponsors.',
      icon: <MdOutlineSportsEsports className='text-3xl font-bold' color='#03AEB5' />
    },
    {
      label: 'Electronic Commerce',
      description: 'Expand online presence with secure payment processing and a user-friendly interface.',
      icon: <MdOutlinePayments className='text-3xl font-bold' color='#03AEB5' />
    },
    {
      label: 'Acquiring',
      description: 'Simplify payment acceptance with fast, reliable processing and comprehensive reporting.',
      icon: <GiReceiveMoney className='text-3xl font-bold' color='#03AEB5' />
    },
    {
      label: 'Hosted Infrastrure',
      description: 'Secure and convinient integrated solutions accessible by a click.',
      icon: <SiContactlesspayment className='text-3xl font-bold' color='#03AEB5' />
    },
    {
      label: 'Fraud and risk management',
      description: 'Detect and prevent fraudulent transactions with real-time monitoring and alerts.',
      icon: <BiCheckShield className='text-3xl font-bold' color='#03AEB5' />
    },
    {
      label: 'Dispute Management',
      description: 'Efficiently manage payment disputes with real-time monitoring and automated workflows.',
      icon: <FaHandsHelping className='text-3xl font-bold' color='#03AEB5' />
    },
  ]
  return (
    <section
      id='products' className='features-section bg-slatish py-8 px-2 text-center'>
      <div className='ft-inner-container'>
        <h1 className='text-2xl font-bold mt-3 mb-3' style={{ color: '#03AEB5' }}>
          Best in class suite of products
        </h1>
        <p className='max-w-2xl ml-auto mr-auto text-gray-600'>
          Cutting-Edge Software Services to Empower Your Businesses
        </p>
        <div className='grid grid-cols-3 gap-4 mt-8'>
          {featureList.map(item =>
            <ProductItem
              key={item.idx}
              icon={item.icon}
              label={item.label}
              description={item.description}
            />
          )}
        </div>
      </div>
    </section>
  )
}

const ProductItem = ({ label, icon, description }) => {
  return (
    <motion.div
      whileHover={{ y: -10 }}
      className='text-center mb-4 cursor-pointer p-3 shadow bg-white'>
      <div className='features-icon-wrapper rounded-md flex items-center justify-center'>
        {icon}
      </div>
      <h6 className='product-label relative'>{label}</h6>
      <p className='mt-6'>{description}</p>
    </motion.div>
  )
}
export default Products;
