import React from 'react'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <section
      id='contact'
      className='partners-section bg-slatish py-10 px-2 text-center'>
      <div className='ft-inner-container'>
        <h1 className='text-2xl font-bold mt-3' style={{ color: '#03AEB5' }}>
        Let's Get in Touch
        </h1>
        <p className='text-base mt-2 mb-5'>Reach Out to Us for More Information</p>
        <ContactForm />
      </div>
    </section>
  )
}

export default Contact