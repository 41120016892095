import React, { useState } from 'react'
import { Drawer, Grid, Row, Col, List } from 'rsuite'
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { toast } from 'react-toastify'

const GameDrawer = ({ activeItem, openDrawer, setDrawerOpen }) => {
    const [size, setSize] = useState('xs');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [, setCardError] = useState(null);

    // const stripe = useStripe();
    // const elements = useElements();

    const enlargeDrawer = () => setSize('full');
    const resetAndCloseDrawer = () => {
        setSize('xs');
        setEmail('');
        setName('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
        setCardError(null);
        return setDrawerOpen(false);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // Validate form data
        if (!email || !name || !address || !city || !state || !zip) {
            alert('Service not available. Try again later.');
            return;
        }
        console.log('button clicked')
        alert('Service not available. Try again later');
        // resetAndCloseDrawer()
        // const { error, paymentMethod } = await stripe.createPaymentMethod({
        //     type: 'card',
        //     card: elements.getElement(CardElement)
        // });

        // if (error) {
        //     setCardError(error.message);
        // } else {
        //     const data = {
        //         email,
        //         name,
        //         address,
        //         city,
        //         state,
        //         zip,
        //         paymentMethodId: paymentMethod.id
        //     };
        //     toast.success('Transaction complete!');
        //     resetAndCloseDrawer();
        // }
    }

    return (
        <Drawer size={size} open={openDrawer} onClose={() => resetAndCloseDrawer()}>
            <Drawer.Header>
                <Drawer.Title className='font-bold font-heading text-white'>{activeItem?.title}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <Grid fluid>
                    <Row className='flex'>
                        <Col className={size === 'xs' ? 'w-full' : ''} md={12} xs={24}>
                            <div className='p-5'>
                                <div className='drawer-image-wrapper relative bg-gray-100'>
                                    <img alt={`${activeItem?.title} thumbnail`} src={`/assets/images/logos/${activeItem?.thumbnail}`} className='object-left inset-0 absolute w-full h-full left-0 top-0 object-contain' />
                                </div>
                            </div>
                            <div className='drawer-game-info px-5 py-3'>
                                <p className='text-base'>{activeItem?.description}</p>
                            </div>
                            <div className='py-3 px-5'>
                                <List bordered className='uppercase font-heading'>
                                    {activeItem?.releaseDate && <List.Item className='bg-transparent'>Release Date: <span className='font-bold'>{activeItem?.releaseDate}</span></List.Item>}
                                    <List.Item className='bg-transparent'>Delivery: <span className='font-bold'>Instantly delivered to your email</span></List.Item>
                                </List>
                            </div>
                            <div className='py-3 px-5'>
                                {size === 'xs' &&
                                    <button
                                        onClick={() => enlargeDrawer()}
                                        className='bg-gradient-to-r from-indigo-700 to-purple-700 h-10 rounded-md px-5'>
                                        Buy Now
                                    </button>
                                }
                            </div>
                        </Col>
                        {openDrawer && size === 'full' &&
                            <Col md={12} xs={24}>
                                <div className='py-3 px-5'>
                                    <h2 className='text-xl font-bold mb-2'>Checkout</h2>
                                    <form
                                        onSubmit={handleSubmit}
                                        className='space-y-6'>
                                        <div>
                                            <label htmlFor='email' className='block text-gray-200 font-bold mb-2'>Email</label>
                                            <input type='email' name='email' id='email' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                        </div>
                                        <div>
                                            <label htmlFor='firstName' className='block text-gray-200 font-bold mb-2'>First Name</label>
                                            <input type='text' name='firstName' id='firstName' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                        </div>
                                        <div>
                                            <label htmlFor='lastName' className='block text-gray-200 font-bold mb-2'>Last Name</label>
                                            <input type='text' name='lastName' id='lastName' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                        </div>
                                        <div>
                                            <label htmlFor='address' className='block text-gray-200 font-bold mb-2'>Address</label>
                                            <input type='text' name='address' id='address' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                        </div>
                                        <div className='flex'>
                                            <div className='w-1/2 mr-2'>
                                                <label htmlFor='city' className='block text-gray-200 font-bold mb-2'>City</label>
                                                <input type='text' name='city' id='city' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                            </div>
                                            <div className='w-1/2 ml-2'>
                                                <label htmlFor='zip' className='block text-gray-200 font-bold mb-2'>ZIP</label>
                                                <input type='text' name='zip' id='zip' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                            </div>
                                        </div>
                                        <hr className='my-6' />
                                        <div>
                                            <label htmlFor='cardNumber' className='block text-gray-200 font-bold mb-2'>Card Number</label>
                                            <input type='text' name='cardNumber' id='cardNumber' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' />
                                        </div>
                                        <div className='flex'>
                                            <div className='w-1/2 mr-2'>
                                                <label htmlFor='expiry' className='block text-gray-200 font-bold mb-2'>Expiry</label>
                                                <input type='text' name='expiry' id='expiry' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='MM / YY' />
                                            </div>
                                            <div className='w-1/2 ml-2'>
                                                <label htmlFor='cvc' className='block text-gray-200 font-bold mb-2'>CVC</label>
                                                <input type='text' name='cvc' id='cvc' required className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='123' />
                                            </div>
                                        </div>
                                        <div className='mt-6'>
                                            <button className='bg-gradient-to-r from-indigo-700 to-purple-700 text-white py-2 px-4 rounded-md hover:bg-indigo-600'>Complete Order</button>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        }
                    </Row>
                </Grid>


            </Drawer.Body>
        </Drawer>
    )
}

export default GameDrawer