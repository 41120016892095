import React from 'react'

const LandingHero = () => {
  return (
    <div className='landing-hero relative'>
      <div className='hero-gradient left-0 top-0 h-full w-full absolute bg-gradient-to-br from-cyan-500 to-blue-500'></div>
      <div className='container relative h-full w-full flex justify-between items-center'>
        <div className='hero-text flex-shrink max-w-xl px-3'>
          <h1 className='text-5xl text-white uppercase mb-3'>Unleash the Power of Technology in Your Services</h1>
          <p className='text-lg text-gray-100'>Revolutionize the Way You Manage Your business with Our Cutting-Edge Solutions.
</p>
        </div>
        <div className='hero-image'>
        </div>
      </div>
    </div>
  )
}

export default LandingHero