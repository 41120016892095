import React, {useState, useEffect} from 'react'
import DefaultLayout from '../layouts/default'
import Hero from '../components/Gmarket/Hero'
import GameGrid from '../components/Gmarket/GameGrid'
import { db as database } from '../_data/services'
import '../marketplace.css'

const Marketplace = () => {
    const [products, setProducts] = useState(database);
    useEffect(() => {
        setProducts(database)
    }, [])
    const searchItem = (query) => {
        if (query.trim() === '') {
            return setProducts(database)
        }
        const filtered = products.filter((item) =>
            item.title.toLowerCase().includes(query.toLowerCase())
        );
        setProducts(filtered);

        return setProducts(filtered)
    }
    return (
        <DefaultLayout>
            <Hero searchItem={searchItem} />
            <GameGrid products={products} />
        </DefaultLayout>
    )
}

export default Marketplace