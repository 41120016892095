import React from 'react'
import APS from '../../../assets/logos/aps.png'
import Taslink from '../../../assets/logos/taslink_logo.png'
import Namescan from '../../../assets/logos/namescan_logo.png'
import Sumsub from '../../../assets/logos/sumsub.png'
// import Uba from '../../../assets/logos/uba.png'
import Sgea from '../../../assets/logos/sgea.png'
import EAC from '../../../assets/logos/eac.png'
import ISFE from '../../../assets/logos/isfe.png'
import IESF from '../../../assets/logos/iesf.png'
import EGDF from '../../../assets/logos/egdf.png'
import AEC from '../../../assets/logos/aec.png'
// import Access from '../../../assets/logos/access.png'
import Konami from '../../../assets/logos/konami.png'
import Steam from '../../../assets/logos/steam.png'
// import KCB from '../../../assets/logos/kcb.svg'
// import BankOfAsia from '../../../assets/logos/bank_of_asia.png'
import styles from './index.module.css'


const LogoBox = ({ image, alt, invertColor }) => {
  return (
    <div className={`${styles.partnerlogo} flex items-center justify-center relative partner-logo-wrapper`}>
      <img src={image} alt={alt} className={`absolute h-full w-full top-0 left-0 object-contain ${invertColor ? 'invert' : ''}`} />
    </div>
  )
}
const Partners = () => {
  return (
    <section
      id='partners'
      className='partners-section py-8 px-2 text-center'>
      <div className='ft-inner-container'>
        <h1 className='text-2xl font-bold mt-3 mb-8' style={{ color: '#03AEB5' }}>
          Our Partners
        </h1>
        <div className='partners-grid inline-grid grid-cols-3 gap-10 lg:gap-20 mb-6'>
          <LogoBox image={IESF} alt='iesf' invertColor={true} />
          <LogoBox image={APS} alt='aps' invertColor={true} />
          <LogoBox image={Taslink} alt='taslink' />
          <LogoBox image={Namescan} alt='namescan' />
          {/* <LogoBox image={Uba} alt='uba' /> */}
          <LogoBox image={AEC} alt='aec' invertColor={true} />
          <LogoBox image={Sumsub} alt='sumsub' />
          {/* <LogoBox image={Access} alt='access' /> */}
          <LogoBox image={Konami} alt='konami' />
          <LogoBox image={Steam} alt='steam' />
          <LogoBox image={Sgea} alt='sgea' />
          <LogoBox image={EAC} alt='eac' />
          <LogoBox image={ISFE} alt='isfe' invertColor={true} />
          <LogoBox image={EGDF} alt='egdf' />
          {/* <LogoBox image={KCB} alt='kcb' /> */}
          {/* <LogoBox image={BankOfAsia} alt='bank of asia' /> */}
        </div>
      </div>
    </section>
  )
}

export default Partners;