import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { ToastContainer } from 'react-toastify'

const DefaultLayout = ({children}) => {
  return (
    <main>
      <Header />
      {children}
      <Footer />
      <ToastContainer limit={1} toastClassName='toast-container' />
    </main>
  )
}

export default DefaultLayout