import React from 'react'
import { motion } from 'framer-motion'


// export const convertPrice = (str) => {
//   const currency = str.split('')[0];
//   if(currency === '$') return str;
//   const price = str.split(`${currency}`)[1];
//   // convert price from euros to dollars 
//   const dollarPrice = (parseInt(price) * 1.08).toFixed();
//   const dollarPriceString = `$${dollarPrice}`
//   return dollarPriceString;
// }


const GameItem = ({ data, id, setActiveItem, openDrawer }) => {
  const handleItemClick = () => {
    openDrawer(true);
    setActiveItem(data);
  }
  return (
      <motion.div 
        onClick={handleItemClick}
        whileHover={{y: -10}}
        id={id} 
        className='cursor-pointer game-item-card flex items-center justify-center relative rounded-md overflow-hidden'>
        <div className='h-full w-full image-overlay absolute bg-gray-100 px-3'>
          <img src={`/assets/images/logos/${data?.thumbnail}`} className='absolute inset-0 w-full h-full rounded-lg object-center object-contain' alt={data.title} />
        </div>
        <div className='info-card absolute w-full bg-gradient-to-l from-indigo-700 to-purple-700 bottom-0 text-white text-center py-3 px-1'>
          <motion.h6>{data.title}</motion.h6>
        </div>
        {/* <motion.div
          className='price-tag absolute top-0 right-0 bg-gradient-to-r from-indigo-700 to-purple-700 border-b-l-2 p-4 text-white rounded-bl-lg'>
            <motion.h6
              className='font-bold'
            >{convertPrice(data?.price)}</motion.h6>
        </motion.div> */}
      </motion.div>
  )
}

export default GameItem