import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Landing from './pages/Landing'
import Marketplace from './pages/Marketplace';

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route
            path='/'
            element={<Landing />}
          />
          <Route
            path='/marketplace'
            element={<Marketplace />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
