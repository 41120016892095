import React from 'react'
import { Link } from 'react-router-dom'
import MPesa from '../assets/logos/mpesa.png'
import Mtn from '../assets/logos/mtn.jpg'
import Airtel from '../assets/logos/airtel.png'
import Vodafone from '../assets/logos/vodafone.png'
import OrangeMoney from '../assets/logos/orange_money.jpg'
// import useAuth from '../hooks/useAuth';

const Footer = () => {
  // const { auth } = useAuth();
  return (
    <footer className="text-white py-12 footer-container">
      <div className='footer-wrapper px-2'>
        <div className="container mx-auto flex flex-col md:flex-row md:space-evenly">
          <div className="mb-6 md:mb-0 md:w-1/3">
            <img className='invert site-logo' src='/assets/images/logos/stayonline_logo.png' alt='stay online logo' />
            <ul className="list-none text-gray-400 mt-4 px-4">
              <li className="mb-2 text-base font-medium">
                StayOnline Ltd Rwanda, Reg. Certificate 102993563
              </li>
              <li className="text-base font-medium">
                Address : 1.1.23 Norrsken house, Kigali KN 78 St Kigali, Rwanda.
              </li>
              <li className='text-base font-medium mt-2'>
                StayOnline Kenya Ltd, Reg. cert No PVT-RXU2G2KB
              </li>
              <li className='text-base font-medium'>
                Langata Road, Nairobi, Kenya
              </li>
              <li className='text-base font-medium mt-2'>
                Stayonline Uganda, Reg. No. 80034806019448
              </li>
              <li className='text-base font-medium mt-2'>
                Stayonline Zambia, Reg. No. 120230053158
              </li>
              <li className="mb-2 text-base font-medium mt-2">
                Email : info@stayonline.tech
              </li>
            </ul>
          </div>
          <div className="mb-6 md:mb-0 md:w-1/4 pt-3">
            <h3 className="text-xl">Customer Service</h3>
            <ul className="list-none text-gray-400 md:mt-12 mt-2">
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Contact Us</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">FAQ</Link></li>
              {/* <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Shipping & Returns</Link></li> */}
            </ul>
          </div>
          {/* <div className="mb-6 md:mb-0 md:w-1/4">
            <h3 className="text-2xl font-bold">Account</h3>
            <ul className="list-none text-gray-400 mt-4"> */}
          {/* {auth?.user
                ? <li className="mb-2">
                  <Link to='' className="text-base font-medium hover:text-gray-300">My Account</Link></li>
                : <li className="mb-2">
                  <Link to='/auth/login' className="text-base font-medium hover:text-gray-300">Sign In</Link></li>
              } */}
          {/* <li className="mb-2">
                  <Link to='' className="text-base font-medium hover:text-gray-300">Sign In</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Order History</Link></li>
            </ul>
          </div> */}
          {/* <div className="md:w-1/4">
            <h3 className="text-2xl font-bold">Contact</h3>
            <ul className="list-none text-gray-400 mt-4">
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Email</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Phone</Link></li>
              <li className="mb-2">
                <Link to='' className="text-base font-medium hover:text-gray-300">Live Chat</Link></li>
            </ul>
          </div> */}
        </div>
        <div className="mt-8 border-t flex-col border-gray-700 pt-8 md:flex ">
          <div className="text-sm font-bold py-2 uppercase">
            Accepted Payments:
          </div>
          <div className="flex mt-4 md:mt-2">
            <img src='/assets/images/footer/payment.png' alt='American Express, VISA, VISA Electron, VISA Debit, VISA Carte Bleue, VISA Electron Carte Bleue, VISA Debit Carte Bleue, VISA Purchasing, Maestro, Mastercard, Debit Mastercard, PayPal,' />
          </div>
          <div className='flex flex-wrap acc-pys mt-5'>
            <img src={MPesa} alt='mpesa' className='mr-4' />
            <img src={Mtn} alt='mtn' className='mr-4' />
            <img src={Airtel} alt='airtel' className='mr-4' />
            <img src={OrangeMoney} alt='orange money' className='mr-4' />
            <img src={Vodafone} alt='vodafone' className='mr-4 mt-3' />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer