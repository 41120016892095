export const db = [
    {
        title: 'Playstation Store',
        thumbnail: 'ps_store.png',
        description: 'Enjoy PlayStation® content with convenient PlayStation®Store Cash Cards, which let you purchase downloadable games, game add-ons, full length movies, TV shows, and even PlayStation®Plus subscriptions. Buy one for yourself or as a gift card for someone else! '
    },
    {
        title: 'Nintendo eStore',
        thumbnail: 'nintendo.svg',
        description: 'Be ready to buy immediately—or give the gift of fun—with a Nintendo eShop Card. Browse and buy digital games on the Nintendo game store, and automatically download them to your Nintendo Switch, Nintendo 3DS system or Wii U console.',
    },
    {
        title: 'Xbox Gift Card',
        thumbnail: 'xbox.svg',
        description: ' With an Xbox Gift Card, give the freedom to pick the gift they want. It can be used to buy the hottest new Xbox full game downloads, apps, movies, TV shows, devices, and more. There are no fees or expiration dates to worry about. This digital gift code is good for purchases at Microsoft Store online, on Windows, and on Xbox. '
    },
    {
        title: 'Twitch',
        thumbnail: 'twitch.svg',
        description: ' Twitch is where millions of people come together live every day to chat, interact, and make their own entertainment together.'
    }
]